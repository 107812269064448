export const Filter = function () {
  const FilterTypes = {
    CONTAINS: 0,
    EXACTLY: 1,
    BEGINS_WITH: 2,
    ENDS_WITH: 3,
    LESS: 4,
    LESS_THAN_EQUAL: 5,
    GREATER: 6,
    GREATER_THAN_EQUAL: 7,
    BETWEEN: 8,
    BETWEEN_AND_INCLUDES: 9,
    BETWEEN_DATES: 10,
    BETWEEN_DATES_AND_INCLUDES: 11,
    IN: 12,
    EMPTY: 13,
    ARRAY_CONTAINS: 14,
    ARRAY_EQUAL: 15,
  };

  this.customPush = function (option) {
    if (option.value !== undefined && option.value !== null) {
      if (Array.isArray(option.value)) {
        if (option.value[0]) {
          this.obj.push(option);
        } else {
          return this;
        }
      } else {
        this.obj.push(option);
      }
    }
    return this;
  };

  this.obj = [];

  this.contains = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.CONTAINS,
    });
  };

  this.exactly = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.EXACTLY,
    });
  };
  this.beginsWith = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.BEGINS_WITH,
    });
  };
  this.endsWith = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.ENDS_WITH,
    });
  };
  this.less = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.LESS,
    });
  };
  this.lessOrEqual = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.LESS_THAN_EQUAL,
    });
  };

  this.greater = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.GREATER,
    });
  };
  this.greaterOrEqual = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.GREATER_THAN_EQUAL,
    });
  };
  this.between = function (field, min, max, inverse = false) {
    return this.customPush({
      field,
      inverse,
      value: [min, max],
      type: FilterTypes.BETWEEN,
    });
  };
  this.betweenAndIncludes = function (field, min, max, inverse = false) {
    return this.customPush({
      field,
      inverse,
      value: [min, max],
      type: FilterTypes.BETWEEN_AND_INCLUDES,
    });
  };
  this.betweenDates = function (field, min, max, inverse = false) {
    return this.customPush({
      field,
      inverse,
      value: [min, max],
      type: FilterTypes.BETWEEN_DATES,
    });
  };
  this.betweenDatesAndIncludes = function (field, min, max, inverse = false) {
    return this.customPush({
      field,
      inverse,
      value: [min, max],
      type: FilterTypes.BETWEEN_DATES_AND_INCLUDES,
    });
  };
  this.in = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.IN,
    });
  };
  this.empty = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.EMPTY,
    });
  };
  this.arrayContains = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.ARRAY_CONTAINS,
    });
  };
  this.arrayEqual = function (field, value, inverse = false) {
    return this.customPush({
      field,
      value,
      inverse,
      type: FilterTypes.ARRAY_EQUAL,
    });
  };

  this.query = function () {
    return this.obj;
  };
};
