export const amountPrecision = (amount, precision = 6) => {
  let num = amount;
  if (typeof amount !== 'number') {
    num = parseFloat(amount);
  }
  const length =
    (num.toString().split('.')[1] && num.toString().split('.')[1].length) || 0;

  if (length < precision) {
    return amount;
  }
  return num.toFixed(precision);
};
