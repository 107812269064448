import React from 'react';
import UpdateForm from '../../../components/Forms/UpdateForm/UpdateForm';
import { useParams } from 'react-router-dom';

const UpdateEdit = () => {
  const params = useParams();

  return <UpdateForm updateProgramId={params.id}></UpdateForm>;
};

export default UpdateEdit;
