// A mock function to mimic making an async request for data
import { apiAxiosExchange, apiAxiosLanding } from '../../../api';

export async function apiGetMe() {
  return await apiAxiosExchange.get('auth/get-session');
}

export async function apiLogin(login, password, captcha) {
  return await apiAxiosExchange.post('auth/login', {
    login,
    password,
    captcha,
  });
}

export async function apiLogout() {
  return await apiAxiosLanding.post('auth/logout');
}
