import React, { useState } from 'react';
import c from './Comments.module.scss';
import { Button, Tabs } from 'antd';
import moment from 'moment';
import { Linkify } from '../../helper/linkify';
import TextArea from 'antd/es/input/TextArea';
import { apiListingSingleComments } from '../../store/slices/listingSlice/listingAPI';
import { toast } from 'react-toastify';
import { apiCreateStakingComment } from '../../store/slices/requestSlice/requestAPI';

const { TabPane } = Tabs;

const Comments = ({
  userComments,
  systemComments,
  getSingleData,
  id,
  requests,
  setUserComments,
  setSystemComments,
}) => {
  const [adminCommentText, setAdminCommentText] = useState(null);

  const addComment = async () => {
    if (requests) {
      await apiCreateStakingComment(id, adminCommentText).then(async e => {
        setUserComments(e.filter(item => item.type === 'user'));
        setSystemComments(e.filter(item => item.type === 'system'));
        setAdminCommentText(null);
        toast.success('Comment added ');
      });
    } else {
      await apiListingSingleComments(id, adminCommentText).then(async () => {
        setAdminCommentText(null);
        await getSingleData();
        toast.success('Comment added ');
      });
    }
  };

  return (
    <div className={c.comments}>
      <Tabs type="card">
        <TabPane tab="Admin" key="1">
          <TextArea
            value={adminCommentText}
            onChange={e => setAdminCommentText(e.target.value)}
            placeholder="Controlled autosize"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          <Button
            type="primary"
            style={{ margin: '0.5rem 0' }}
            onClick={addComment}>
            Add
          </Button>
          <div>
            {userComments.length ? (
              <ul className={c.comment__list}>
                {userComments.map(comment => (
                  <li className={c.comment__item} key={comment.id}>
                    <div className={c.comment__head}>
                      <div className={c.comment__date}>
                        {moment(comment.date).format('YYYY/MM/DD HH:mm:ss')}
                      </div>
                      <div className={c.comment__email}>
                        {comment.user.email}
                      </div>
                    </div>
                    <div className={c.comment__body}>{comment.comment}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={c.comment__empty}>No user comments</div>
            )}
          </div>
        </TabPane>
        <TabPane tab="System" key="2">
          <div>
            {systemComments.length ? (
              <ul className={c.comment__list}>
                {systemComments.map(comment => (
                  <li className={c.comment__item} key={comment.id}>
                    <div className={c.comment__head}>
                      <div className={c.comment__date}>
                        {moment(comment.date).format('YYYY/MM/DD HH:mm:ss')}
                      </div>
                      <div className={c.comment__email}>
                        {comment.user.email}
                      </div>
                    </div>
                    <div className={c.comment__body}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: Linkify(comment.comment),
                        }}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className={c.comment__empty}>No system comments</div>
            )}
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Comments;
