import React from 'react';
import { useParams } from 'react-router-dom';
import AirdropForm from '../../../components/Forms/AirdropForm/AirdropForm';

const AirdropEdit = () => {
  const params = useParams();
  return <AirdropForm airdropProgramId={params.id}></AirdropForm>;
};

export default AirdropEdit;
