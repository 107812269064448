import { Navigate, useRoutes } from 'react-router-dom';
import LayoutComponent from './components/Layout/LayoutComponent';
import StakingList from './pages/Staking/StakingList/StakingList';
import ListingList from './pages/Listing/ListingList/ListingList';
import Login from './pages/Login/Login';
import { useSelector } from 'react-redux';
import ListingEdit from './pages/Listing/ListingEdit/ListingEdit';
import ListingCreate from './pages/Listing/ListingCreate/ListingCreate';
import StakingCreate from './pages/Staking/StakingCreate/StakingCreate';
import StakingEdit from './pages/Staking/StakingEdit/StakingEdit';
import AirdropList from './pages/Airdrop/AirdropList/AirdropList';
import AirdropEdit from './pages/Airdrop/AirdropEdit/AirdropEdit';
import AirdropCreate from './pages/Airdrop/AirdropCreate/AirdropCreate';
import SwapList from './pages/Swap/SwapList/SwapList';
import SwapEdit from './pages/Swap/SwapEdit/SwapEdit';
import SwapCreate from './pages/Swap/SwapCreate/SwapCreate';
import UpdateList from './pages/Update/UpdateList/UpdateList';
import UpdateEdit from './pages/Update/UpdateEdit/UpdateEdit';
import UpdateCreate from './pages/Update/UpdateCreate/UpdateCreate';

const publicRoutes = [
  { path: '', element: <Navigate to={`/login`} replace /> },
  {
    path: '/',
    children: [
      {
        path: '',
        element: <Navigate to={`/login`} replace />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      { path: '*', element: <Navigate to={`/login`} replace /> },
    ],
  },
  { path: '*', element: <Navigate to={`/login`} replace /> },
];

const privateRoutes = [
  {
    path: '/',
    element: <LayoutComponent />,
    children: [
      {
        path: '',
        element: <Navigate to={`/listing`} replace />,
      },

      {
        path: 'listing',
        element: <ListingList />,
      },
      {
        path: 'listing/:id',
        element: <ListingEdit />,
      },
      {
        path: 'listing/create',
        element: <ListingCreate />,
      },
      {
        path: 'staking',
        element: <StakingList />,
      },
      {
        path: 'staking/create',
        element: <StakingCreate />,
      },
      {
        path: 'staking/:id',
        element: <StakingEdit />,
      },
      { path: 'airdrop', element: <AirdropList /> },
      { path: 'airdrop/create', element: <AirdropCreate /> },
      {
        path: 'airdrop/:id',
        element: <AirdropEdit />,
      },
      { path: 'swap', element: <SwapList /> },
      { path: 'swap/:id', element: <SwapEdit /> },
      { path: 'swap/create', element: <SwapCreate /> },
      { path: 'update', element: <UpdateList /> },
      { path: 'update/:id', element: <UpdateEdit /> },
      { path: 'update/create', element: <UpdateCreate /> },
      { path: '*', element: <Navigate to={`/listing`} replace /> },
    ],
  },
];

function Router() {
  const isLoggedIn = useSelector(state => state.user.userData.userId);

  const routes = {
    privateRoutes: useRoutes(privateRoutes),
    publicRoutes: useRoutes(publicRoutes),
  };

  return isLoggedIn ? routes.privateRoutes : routes.publicRoutes;
}

export default Router;
