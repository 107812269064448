import c from './NumericInput.module.scss';

const NumericInput = ({
  value = 0,
  onBlur,
  onChange,
  type = 'text',
  precision = 6,
  maxLength = 20,
}) => {
  const handleChange = e => {
    const inputValue = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      onChange(inputValue);
      const [int, decimals] = inputValue.split('.');
      if (decimals?.length >= precision) {
        const newValue = int + '.' + decimals.slice(0, precision);
        onChange(newValue);
      } else {
        onChange(inputValue);
      }
    }
  };

  const handleBlur = e => {
    let value = e.target.value;
    if (value) {
      let valueTemp = e.target.value;
      if (value.charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1);
      }

      valueTemp = valueTemp.replace(/0*(\d+)/, '$1');
      onChange(Number(valueTemp));

      const [, decimals] = valueTemp.split('.');

      decimals?.length > 6 && Number(valueTemp) < 1
        ? onChange(Number(valueTemp).toFixed(decimals.length))
        : onChange(Number(valueTemp));
    }
    onBlur(value);
  };

  return (
    <div className={c.inputField}>
      <input
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        className={c.input}
        type={type}
        maxLength={maxLength}
      />
    </div>
  );
};

export default NumericInput;
