import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import c from '../ListingForm/ListingForm.module.scss';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
} from 'antd';
import {
  LeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import Loader from '../../Loader/Loader';
import moment from 'moment';
import { toast } from 'react-toastify';
import Comments from '../../Comments/Comments';
import Modal from '../../Modal/Modal';
import {
  apiEditRequestById,
  apiGetRequestById,
  apiRequestCreate,
  apiRequestSelectOptions,
  apiSearchUser,
} from '../../../store/slices/requestSlice/requestAPI';
import ImageUploader from '../../ImageUploader/ImageUploader';
import { CopyToClipboard } from '../../../helper/copyToClipboard';
import NumericInput from '../../Ui/NumericInput/NumericInput';

const { TextArea } = Input;

const SwapEdit = ({ create }) => {
  const params = useParams();
  const { Option } = Select;
  const navigate = useNavigate();
  const [singleData, setSingleData] = useState({});
  const [selectOptions, setSelectOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [relationWithCoinOption, setRelationWithCoinOption] = useState();
  const [listingStatus, setListingStatus] = useState();
  const [rejectPopup, setRejectPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonOption, setRejectReasonOption] = useState('');
  const [form] = Form.useForm();
  const [userComments, setUserComments] = useState([]);
  const [systemComments, setSystemComments] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [logoUploadImg, setLogoUploadImg] = useState(null);
  const [logoUploadImgAdmin, setLogoUploadImgAdmin] = useState(null);
  const [logoUploadErrorUser, setLogoUploadErrorUser] = useState(false);
  const [logoUploadErrorAdmin, setLogoUploadErrorAdmin] = useState(false);

  useEffect(() => {
    if (listingStatus === 'rejected') {
      setRejectPopup(true);
      setRejectReason(selectOptions?.rejectReason[0].value);
      setRejectReasonOption(selectOptions?.rejectReason[0].value);
    } else {
      setRejectPopup(false);
    }
  }, [listingStatus]);

  useEffect(async () => {
    await apiRequestSelectOptions()
      .then(setSelectOptions)
      .catch(e => console.log(e));
    if (!create) {
      await getSingleData();
    } else {
      setLoading(false);
    }
  }, []);

  const onBlur = () => {};

  const validateMsg = (type, value) => {
    let message = '';
    if (type === 'min') {
      message = `Should be more than ${value}`;
      return message;
    } else if (type === 'max') {
      message = `should not be more than ${value}`;
      return message;
    }
  };

  const validateMinAmount = min => {
    return (_, value) => {
      if (value >= min) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const validateMaxAmount = max => {
    return (_, value) => {
      if (value <= max) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const getSingleData = async () => {
    setLoading(true);
    await apiGetRequestById(params.id)
      .then(e => {
        e.created = moment(singleData.created);
        form.setFieldsValue({ ...e });
        setSingleData(e);
        setRelationWithCoinOption(e.relationWithCoin.relation);
        setLogoUploadImg({ image: e.extra.swap.logoName });
        setLogoUploadImgAdmin({ image: e.extra.swap.logoNameAdmin });
        setRejectReason(e.extra.swap.rejectReason);
        setSelectedUser(e.user);
        if (e.comments?.length) {
          setUserComments(e.comments.filter(item => item.type === 'user'));
          setSystemComments(e.comments.filter(item => item.type === 'system'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editData = async values => {
    setLogoUploadErrorAdmin(false);
    const data = {
      ...values,
      type: 'swap',
      created: undefined,
      paymentAmount: values.paymentAmount > 0 ? values.paymentAmount : 0,
    };
    data.extra.swap.logoName = logoUploadImg ? logoUploadImg.image : '';
    data.extra.swap.logoNameAdmin = logoUploadImgAdmin?.image
      ? logoUploadImgAdmin?.image
      : '';
    data.user = selectedUser;
    if (create) {
      await apiRequestCreate(data)
        .then(async e => {
          navigate(`/swap/${e.id}`);
          toast.success('Save');
        })
        .catch(e => toast.error(e.message));
    } else {
      data.extra.swap.rejectReason = rejectReason;

      await apiEditRequestById(params.id, {
        ...data,
      })
        .then(async () => {
          await getSingleData();
          toast.success('Save');
        })
        .catch(e => toast.error(e.message));
    }
  };

  const onSearch = async searchText => {
    if (searchText.length <= 3) {
      setUserList([]);
      return;
    }
    await apiSearchUser(searchText).then(e =>
      setUserList(
        e.users.map(item => {
          return {
            value: item.username + '(' + item.email + ')',
            email: item.email,
            id: item.id,
            role: item.role,
            username: item.username,
          };
        }),
      ),
    );
  };

  const onSelect = (_, data) => {
    delete data.value;
    setSelectedUser(data);
  };

  const cancelRejected = () => {
    setRejectReason(selectOptions?.rejectReason[0].value);
    setRejectReasonOption(selectOptions?.rejectReason[0].value);
    setRejectPopup(false);
  };

  return (
    <>
      <h1 className="title">Listing Single</h1>
      <div className={c.listingEdit}>
        {loading ? (
          <Loader fixed={false} />
        ) : (
          <div>
            <Form
              form={form}
              name="stakingEdit"
              onFinish={editData}
              //shouldUpdate
              onValuesChange={() => {}}
              autoComplete="off"
              initialValues={singleData}
              scrollToFirstError>
              <div className={c.single}>
                <div className={c.stakingInfo}>
                  <div>
                    <div className={c.stakingInfo__buttons}>
                      <Button
                        type="primary"
                        icon={<LeftOutlined />}
                        onClick={() => navigate(-1)}>
                        Back
                      </Button>
                      <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={() => form.resetFields()}>
                        Reset
                      </Button>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          icon={<SaveOutlined />}>
                          Save
                        </Button>
                      </Form.Item>
                    </div>
                    <div className={c.form__inner}>
                      <div className={c.singleGroup__title}>
                        Coin/Token information
                      </div>
                      <div className={c.form__list}>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              label="User">
                              <AutoComplete
                                options={userList}
                                notFoundContent={'Nothing found'}
                                onSelect={onSelect}
                                onSearch={onSearch}
                                placeholder="Search User"
                                defaultValue={
                                  singleData?.user
                                    ? singleData.user.username +
                                      '(' +
                                      singleData.user.email +
                                      ')'
                                    : ''
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="code"
                              label="Symbol"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                  whitespace: true,
                                },
                              ]}>
                              <Select
                                placeholder="Select "
                                style={{ width: '100%' }}>
                                {selectOptions?.availableCurrencies?.map(
                                  item => (
                                    <Option key={item} value={item}>
                                      {item}
                                    </Option>
                                  ),
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'newCode']}
                              label="New Symbol">
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'newName']}
                              label="New Name">
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ display: 'flex' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                                width: '50%',
                              }}
                              name={['extra', 'swap', 'proportion', 'old']}
                              label="Prop. Old"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                },
                              ]}>
                              <InputNumber
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                            <div className={c.proportion}>/</div>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                                width: '50%',
                              }}
                              name={['extra', 'swap', 'proportion', 'new']}
                              label="Prop. New"
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                },
                              ]}>
                              <InputNumber
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="contactEmail"
                              label="Contact Email"
                              rules={[
                                {
                                  type: 'email',
                                },
                                {
                                  required: true,
                                  message: 'Required',
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name="telegram"
                              label="Telegram"
                              rules={[
                                {
                                  message: 'Required',
                                  whitespace: true,
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <div className={c.aside__itemTitle}>
                              <span
                                style={{
                                  display: 'inline-block',
                                  fontFamily: 'SimSun, sans-serif',
                                  color: '#ff4d4f',
                                  fontSize: '14px',
                                  marginRight: '5px',
                                }}>
                                *
                              </span>
                              Relations with the C/T
                            </div>
                            <Form.Item style={{ width: '100%' }}>
                              <Input.Group
                                style={{ display: 'flex', width: '100%' }}>
                                <Form.Item
                                  style={{
                                    width: '100%',
                                    marginBottom: 0,
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required',
                                    },
                                  ]}
                                  name={['relationWithCoin', 'relation']}>
                                  <Select
                                    placeholder="Select province"
                                    style={{ width: '100%' }}
                                    onChange={e => {
                                      setRelationWithCoinOption(e);
                                      form.setFieldsValue({
                                        relationWithCoin: { value: null },
                                      });
                                    }}>
                                    {selectOptions?.relationWithCoin?.map(
                                      item => (
                                        <Option
                                          key={item.value}
                                          value={item.value}>
                                          {item.name}
                                        </Option>
                                      ),
                                    )}
                                  </Select>
                                </Form.Item>
                                {relationWithCoinOption === 'other' && (
                                  <Form.Item
                                    style={{
                                      width: '100%',
                                      marginBottom: 0,
                                    }}
                                    name={['relationWithCoin', 'value']}
                                    rules={[
                                      {
                                        required:
                                          relationWithCoinOption === 'other',
                                        message: 'Required',
                                      },
                                    ]}>
                                    <Input
                                      style={{ width: '100%' }}
                                      placeholder="Input street"
                                    />
                                  </Form.Item>
                                )}
                              </Input.Group>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'forkedFrom']}
                              label="Which Coin are you forked from"
                              rules={[
                                {
                                  required: false,
                                  whitespace: true,
                                },
                              ]}>
                              <Input onDoubleClick={CopyToClipboard} />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              rules={[
                                {
                                  required: true,
                                },
                              ]}
                              name={['extra', 'swap', 'blockchain']}
                              label="Chose your blockchain">
                              <Select placeholder="select your blockchain">
                                {selectOptions?.blockchain?.map(item => (
                                  <Option key={item.value} value={item.value}>
                                    {item.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'openSource']}
                              label="Is your project open-source?">
                              <Radio.Group>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'website']}
                              label="Website link "
                              rules={[
                                {
                                  required: true,
                                  message: 'Required',
                                  whitespace: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'github']}
                              label="GitHub repo"
                              rules={[
                                {
                                  required: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'explorer']}
                              label="Explorer link">
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'totalSupply']}
                              label="Total Coin/Token supply"
                              validateTrigger="onBlur"
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  message: validateMsg('min', 0),
                                  validator: validateMinAmount(0),
                                },
                                {
                                  message: validateMsg('max', 1000000000),
                                  validator: validateMaxAmount(1000000000),
                                },
                              ]}>
                              <NumericInput
                                precision={0}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'totalSupply')
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={c.stakingInfo__item}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'price']}
                              label="Current Coin/Token price"
                              validateTrigger="onBlur"
                              rules={[
                                {
                                  required: false,
                                },
                                {
                                  message: validateMsg('min', 0),
                                  validator: validateMinAmount(0),
                                },
                                {
                                  message: validateMsg('max', 1000000000),
                                  validator: validateMaxAmount(1000000000),
                                },
                              ]}>
                              <NumericInput
                                precision={10}
                                onDoubleClick={CopyToClipboard}
                                onBlur={currentValue =>
                                  onBlur(currentValue, 'price')
                                }
                              />
                            </Form.Item>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'currentlyTradingOn']}
                              label="Exchanges where your Coin/Token is trading on"
                              rules={[
                                {
                                  required: false,
                                  whitespace: false,
                                },
                              ]}>
                              <Input
                                style={{ width: '100%' }}
                                onDoubleClick={CopyToClipboard}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Link to CMC or CG
                            </div>
                            <Form.List name="trackingSites">
                              {(initialValue, { add, remove }) => (
                                <>
                                  {initialValue.map(field => (
                                    <Space
                                      key={field.key}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...field}
                                        label="Site"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[field.name, 'site']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select>
                                          {selectOptions?.trackingSites?.map(
                                            item => (
                                              <Option
                                                key={item.value}
                                                value={item.value}>
                                                {item.name}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        label="Url"
                                        name={[field.name, 'url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing price',
                                          },
                                        ]}>
                                        <Input
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '48.6%' }}>
                            <div className={c.aside__itemTitle}>
                              Community channels and links
                            </div>
                            <Form.List name="communityChannels">
                              {(initialValue, { add, remove }) => (
                                <>
                                  {initialValue.map(field => (
                                    <Space
                                      key={field.key}
                                      align="baseline"
                                      style={{
                                        width: '100%',
                                        alignItems: 'center',
                                      }}>
                                      <Form.Item
                                        {...field}
                                        label="Community"
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        name={[field.name, 'community']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing sight',
                                          },
                                        ]}>
                                        <Select>
                                          {selectOptions?.communityChannels?.map(
                                            item => (
                                              <Option
                                                key={item.value}
                                                value={item.value}>
                                                {item.name}
                                              </Option>
                                            ),
                                          )}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        {...field}
                                        style={{
                                          flexDirection: 'column',
                                        }}
                                        label="Url"
                                        name={[field.name, 'url']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Missing price',
                                          },
                                        ]}>
                                        <Input
                                          onDoubleClick={e =>
                                            CopyToClipboard(e)
                                          }
                                        />
                                      </Form.Item>
                                      <MinusCircleOutlined
                                        onClick={() => remove(field.name)}
                                      />
                                    </Space>
                                  ))}
                                  <Form.Item>
                                    <Button
                                      type="dashed"
                                      onClick={() => add()}
                                      block
                                      icon={<PlusOutlined />}>
                                      Add sites
                                    </Button>
                                  </Form.Item>
                                </>
                              )}
                            </Form.List>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <Form.Item
                              style={{
                                flexDirection: 'column',
                              }}
                              name={['extra', 'swap', 'info']}
                              label="Any other information that you would like to provide?">
                              <TextArea
                                placeholder="Controlled autosize"
                                autoSize={{ minRows: 3, maxRows: 5 }}
                              />
                            </Form.Item>
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <div className={c.singleGroup__title}>Logo</div>
                            <ImageUploader
                              logoUploadImg={logoUploadImg}
                              setLogoUploadImg={setLogoUploadImg}
                              type={'user'}
                              logoUploadError={logoUploadErrorUser}
                              setLogoUploadError={setLogoUploadErrorUser}
                            />
                          </div>
                        </>
                        <>
                          <div
                            className={c.stakingInfo__item}
                            style={{ width: '100%' }}>
                            <div className={c.singleGroup__title}>
                              Logo Admin
                            </div>
                            <ImageUploader
                              logoUploadImg={logoUploadImgAdmin}
                              setLogoUploadImg={setLogoUploadImgAdmin}
                              type={'admin'}
                              logoUploadError={logoUploadErrorAdmin}
                              setLogoUploadError={setLogoUploadErrorAdmin}
                            />
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={c.aside}>
                  <div className={c.aside__inner}>
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name={['extra', 'swap', 'status']}
                        label="Status">
                        <Select
                          placeholder="select status"
                          onChange={e => {
                            setListingStatus(e);
                            setRejectReasonOption('');
                            setRejectReason('');
                          }}>
                          {selectOptions?.swapStatus?.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    {rejectReason && (
                      <div className={c.aside__item}>
                        <div className={c.aside__itemTitle}>Reject Reason</div>
                        <div
                          style={{ fontSize: '12px', marginBottom: '1.5rem' }}>
                          {rejectReason}
                        </div>
                      </div>
                    )}
                    <div className={c.aside__item}>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name={['extra', 'swap', 'type']}
                        rules={[
                          {
                            required: true,
                            message: 'Required',
                          },
                        ]}
                        label="Type">
                        <Select placeholder="select type">
                          {selectOptions?.swapType?.map(item => (
                            <Option key={item.value} value={item.value}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <Form.Item
                        name="created"
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Created">
                        <DatePicker style={{ width: '100%' }} disabled />
                      </Form.Item>
                    </div>
                    <div className={c.aside__item}>
                      <div className={c.aside__itemTitle}>
                        Payment Amount (USDT)
                      </div>
                      <Form.Item
                        style={{
                          flexDirection: 'column',
                        }}
                        name="paymentAmount"
                        validateTrigger="onBlur"
                        rules={[
                          {
                            message: validateMsg('min', 0),
                            validator: validateMinAmount(0),
                          },
                          {
                            message: validateMsg('max', 1000000000),
                            validator: validateMaxAmount(1000000000),
                          },
                        ]}>
                        <NumericInput
                          precision={6}
                          disabled={singleData?.order?.status === 'paid'}
                          onDoubleClick={CopyToClipboard}
                          onBlur={currentValue =>
                            onBlur(currentValue, 'paymentAmount')
                          }
                        />
                      </Form.Item>
                    </div>
                    {!!singleData.totalPaymentAmount && (
                      <div className={c.aside__item}>
                        <div className={c.aside__itemTitle}>
                          Total Amount (USDT)
                        </div>
                        <div>{singleData.totalPaymentAmount}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Form>
            {rejectPopup && (
              <Modal
                title={'Reject Reason'}
                onOk={() => setRejectPopup(false)}
                onClose={() => cancelRejected()}>
                <div className={c.stakingInfo__item} style={{ width: '100%' }}>
                  <Select
                    style={{ width: '300px' }}
                    defaultValue={selectOptions?.rejectReason[0].value}
                    onChange={e => {
                      setRejectReason(e);
                      setRejectReasonOption(e);
                    }}>
                    {selectOptions?.rejectReason?.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                {rejectReasonOption === 'other' && (
                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '100%', marginTop: '1rem' }}>
                    <TextArea
                      initialValue={singleData.name}
                      placeholder="Controlled autosize"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      onChange={e => setRejectReason(e.target.value)}
                    />
                  </div>
                )}
              </Modal>
            )}
            {!create && (
              <Comments
                userComments={userComments}
                systemComments={systemComments}
                setUserComments={setUserComments}
                setSystemComments={setSystemComments}
                getSingleData={getSingleData}
                id={params.id}
                requests
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SwapEdit;
