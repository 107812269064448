import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';

import c from './LayoutComponent.module.scss';

import logoImg from '../../assets/images/logo.png';
import { logoutAsync } from '../../store/slices/userSlice/userSlice';
import classNames from 'classnames';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const { Header, Sider, Content } = Layout;

const LayoutComponent = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const { email } = useSelector(state => state.user.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const content = useRef(null);
  const [scrollTopActive, setScrollTopActive] = useState(false);

  useEffect(() => {
    if (content) {
      content.current.addEventListener('scroll', () => {
        setScrollTopActive(content.current.scrollTop > 100);
      });
    }
  }, []);

  return (
    <Layout className={c.layout}>
      <Header className={c.header}>
        <div>
          <Link to={'/listing'}>
            <img src={logoImg} alt="" />
          </Link>
        </div>
        <div className={c.logout}>
          {email && <div>{email}</div>}
          <button
            onClick={() => dispatch(logoutAsync()).then(navigate('/login'))}
            className={c.logoutButton}>
            Logout
          </button>
        </div>
      </Header>
      <Layout>
        <Sider
          collapsed={menuCollapsed}
          collapsedWidth={100}
          collapsible
          onCollapse={() => setMenuCollapsed(prevState => !prevState)}>
          <div className={c.linkColumn}>
            <NavLink
              to="/listing"
              className={({ isActive }) =>
                classNames(c.link, isActive && c.active)
              }>
              Listing
            </NavLink>
          </div>
          <div className={c.linkColumn}>
            <NavLink
              to="/staking"
              className={({ isActive }) =>
                classNames(c.link, isActive && c.active)
              }>
              Staking
            </NavLink>
          </div>
          <div className={c.linkColumn}>
            <NavLink
              to="/airdrop"
              className={({ isActive }) =>
                classNames(c.link, isActive && c.active)
              }>
              Airdrop
            </NavLink>
          </div>
          <div className={c.linkColumn}>
            <NavLink
              to="/swap"
              className={({ isActive }) =>
                classNames(c.link, isActive && c.active)
              }>
              Swap
            </NavLink>
          </div>
          <div className={c.linkColumn}>
            <NavLink
              to="/update"
              className={({ isActive }) =>
                classNames(c.link, isActive && c.active)
              }>
              Update
            </NavLink>
          </div>
        </Sider>
        <Content>
          <div className={c.content} ref={content}>
            <Outlet />
            {scrollTopActive && (
              <ScrollToTop
                onClick={() => {
                  content.current.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              />
            )}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
