import React from 'react';
import c from './Loader.module.scss';
import classNames from 'classnames';

const Loader = ({ fixed = true }) => {
  return (
    <div className={classNames(c.loader, fixed && c.loader_fixed)}>
      <div className={c.ldsSpinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
