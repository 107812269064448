import React from 'react';
import { useParams } from 'react-router-dom';
import StakingForm from '../../../components/Forms/StakingForm/StakingForm';

const StakingEdit = () => {
  const params = useParams();
  return <StakingForm stakingProgramId={params.id} />;
};

export default StakingEdit;
