import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMeAsync } from './store/slices/userSlice/userSlice';
import Loader from './components/Loader/Loader';
import Router from './Router';

const App = () => {
  const { loading } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    dispatch(getMeAsync());
    setFirstLoad(true);
  }, [dispatch]);

  if (loading || !firstLoad) {
    return <Loader />;
  }
  return <Router />;
};

export default App;
