import React, { useState } from 'react';
import c from './ImageUploader.module.scss';
import classNames from 'classnames';
import { apiImageUpload } from '../../store/slices/listingSlice/listingAPI';
import { triggerBase64Download } from 'react-base64-downloader';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { renameFile } from '../../helper/renameFile';

const titles = {
  user: 'Upload User logo',
  admin: 'Upload Admin logo',
};

const ImageUploader = ({
  logoUploadError,
  setLogoUploadError,
  logoUploadImg,
  setLogoUploadImg,
  type,
  code,
}) => {
  const [logoUploadTypeError, setLogoUploadTypeError] = useState(false);

  function downloadImage(url, name) {
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('An error sorry'));
  }

  const imageToBase64 = e => {
    if (
      !(
        (e.target.files[0]?.type === 'image/png' ||
          e.target.files[0]?.type === 'image/svg+xml') &&
        e.target.files[0]?.size < 1048576
      )
    ) {
      setLogoUploadTypeError(true);
      return;
    }
    setLogoUploadTypeError(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setLogoUploadImg({ name: e.target.files[0].name, image: reader.result });
      setLogoUploadError(false);
    };

    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const loadImageAdmin = async e => {
    if (
      !(
        e.target.files[0]?.type === 'image/png' &&
        e.target.files[0]?.size < 1048576
      )
    ) {
      setLogoUploadTypeError(true);
      return;
    }

    setLogoUploadTypeError(false);
    let formData = new FormData();
    const file = renameFile(e.target.files[0], code + '.png');
    formData.append('file', file);
    formData.append('currDir', 'logos');
    await apiImageUpload(formData).then(result => {
      setLogoUploadImg({
        image:
          result.publicAssetsUrl + '/' + result.currDir + '/' + code + '.png',
      });
    });
  };

  return (
    <div className={c.imageUploader}>
      {logoUploadImg?.image ? (
        <>
          <div className={c.upload__inner}>
            <div className={c.upload__img}>
              <img src={logoUploadImg?.image} alt="" />
            </div>
            <div className={c.upload__name}>{logoUploadImg?.name}</div>
            <div className={c.upload__info}>
              <button
                className={c.upload__remove}
                onClick={() => setLogoUploadImg(null)}>
                <svg>
                  <use xlinkHref={'#trash'} />
                </svg>
              </button>
            </div>
          </div>
          {type !== 'admin' ? (
            <div style={{ marginTop: '1rem' }}>
              <Button
                type="primary"
                htmlType={'button'}
                icon={<DownloadOutlined />}
                onClick={() =>
                  triggerBase64Download(logoUploadImg?.image, 'logo')
                }>
                Click to download
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: '1rem' }}>
              <Button
                type="primary"
                htmlType={'button'}
                icon={<DownloadOutlined />}
                onClick={() =>
                  downloadImage(
                    logoUploadImg?.image,
                    'logo.' + logoUploadImg?.image.slice(-3),
                  )
                }>
                Click to download
              </Button>
            </div>
          )}
        </>
      ) : (
        <label
          className={classNames(c.uploadLabel, logoUploadError && c.error)}>
          <input
            type="file"
            accept={type === 'admin' ? 'image/png' : 'image/png, image/svg+xml'}
            onChange={type === 'admin' ? loadImageAdmin : imageToBase64}
          />
          <div className={c.uploadPhoto__title}>
            <div className={c.uploadPhoto__icon}>
              <svg>
                <use xlinkHref={'#upload'} />
              </svg>
            </div>
            <div>{titles[type]}</div>
          </div>
        </label>
      )}
      <div
        className={classNames(
          c.uploadPhoto__text,
          logoUploadTypeError && c.error,
        )}>
        {type === 'admin'
          ? 'Upload your coin/token’s logo in PNG format with a transparent\n' +
            'background. Supported size is 1MB.'
          : 'Upload your coin/token’s logo in PNG or SVG format with a transparent\n' +
            'background. Supported size is 1MB.'}
      </div>
    </div>
  );
};

export default ImageUploader;
