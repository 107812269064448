export function Linkify(inputText) {
  const replacePattern1 =
    /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  let replacedText = inputText.replace(
    replacePattern1,
    '<a class="word-break" href="$1" target="_blank">$1</a>',
  );

  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(
    replacePattern2,
    '$1<a class="word-break" href="http://$2" target="_blank">$2</a>',
  );

  const replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
  replacedText = replacedText.replace(
    replacePattern3,
    '<a class="word-break" href="mailto:$1">$1</a>',
  );

  return replacedText;
}
