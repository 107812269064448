import React, { useEffect, useState } from 'react';
import { AutoComplete, Button, DatePicker, Form, Input, Select } from 'antd';

import c from './AirdropForm.module.scss';
import { LeftOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  apiEditRequestById,
  apiGetRequestById,
  apiRequestCreate,
  apiRequestSelectOptions,
  apiSearchUser,
} from '../../../store/slices/requestSlice/requestAPI';
import moment from 'moment';
import Loader from '../../Loader/Loader';
import Comments from '../../Comments/Comments';
import classNames from 'classnames';

import checkMark from '../../../assets/images/chec-mark.png';
import Modal from '../../Modal/Modal';
import { CopyToClipboard } from '../../../helper/copyToClipboard';
import NumericInput from '../../Ui/NumericInput/NumericInput';

const AirdropForm = ({ airdropProgramId }) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [singleData, setSingleData] = useState();
  const navigate = useNavigate();
  const [selectOptions, setSelectOptions] = useState([]);
  const [relationWithCoinOption, setRelationWithCoinOption] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userComments, setUserComments] = useState([]);
  const [systemComments, setSystemComments] = useState([]);
  const [airdropTypes, setAirdropTypes] = useState([]);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [listingStatus, setListingStatus] = useState();
  const [rejectPopup, setRejectPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [rejectReasonOption, setRejectReasonOption] = useState('');

  useEffect(() => {
    if (listingStatus === 'rejected') {
      setRejectPopup(true);
      setRejectReason(selectOptions?.rejectReason[0].value);
      setRejectReasonOption(selectOptions?.rejectReason[0].value);
    } else {
      setRejectPopup(false);
    }
  }, [listingStatus]);

  useEffect(async () => {
    await getAirdropById();
    await apiRequestSelectOptions()
      .then(e => setSelectOptions(e))
      .catch(e => console.log(e));
  }, []);

  const getAirdropById = async () => {
    setLoading(true);
    if (!airdropProgramId) {
      setLoading(false);
      return;
    }
    await apiGetRequestById(airdropProgramId)
      .then(e => {
        e.created = e.created ? moment(e.created) : e.created;
        if (e.comments?.length) {
          setUserComments(e.comments.filter(item => item.type === 'user'));
          setSystemComments(e.comments.filter(item => item.type === 'system'));
        }
        setRejectReason(e.extra.airdrop.rejectReason);
        setRelationWithCoinOption(e.relationWithCoin.relation);
        setAirdropTypes(e.extra.airdrop.type);
        setSingleData(e);
        setSelectedUser(e.user);
      })
      .finally(() => setLoading(false));
  };

  const editData = async airdropData => {
    const values = JSON.parse(JSON.stringify(airdropData));
    const data = {
      ...values,
      type: 'airdrop',
      created: undefined,
    };
    data.extra.airdrop.type = airdropTypes;
    data.user = selectedUser;
    if (airdropProgramId) {
      data.extra.airdrop.rejectReason = rejectReason;
      await apiEditRequestById(airdropProgramId, data)
        .then(e => {
          toast.success('Save');
          form.setFieldsValue({ ...e });
          getAirdropById();
        })
        .catch(e => toast.error(e.message));
    } else {
      await apiRequestCreate({
        ...data,
      })
        .then(e => {
          toast.success('Save');
          navigate(`/airdrop/${e.id}`);
        })
        .catch(e => toast.error(e.message));
    }
  };

  const toggleAirdropTypes = value => {
    if (airdropTypes.some(e => e === value)) {
      setAirdropTypes(prevState => prevState.filter(e => e !== value));
    } else {
      setAirdropTypes(prevState => [...prevState, value]);
    }
  };

  const onSearch = async searchText => {
    if (searchText.length < 3) {
      setUserList([]);
      return;
    }
    await apiSearchUser(searchText).then(e =>
      setUserList(
        e.users.map(item => {
          return {
            value: item.username + '(' + item.email + ')',
            email: item.email,
            id: item.id,
            role: item.role,
            username: item.username,
          };
        }),
      ),
    );
  };

  const onSelect = (_, data) => {
    delete data.value;
    setSelectedUser(data);
  };

  const onBlur = () => {};

  const validateMsg = (type, value) => {
    let message = '';
    if (type === 'min') {
      message = `Should be more than ${value}`;
      return message;
    } else if (type === 'max') {
      message = `should not be more than ${value}`;
      return message;
    }
  };

  const validateMinAmount = min => {
    return (_, value) => {
      if (value >= min) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const validateMaxAmount = max => {
    return (_, value) => {
      if (value <= max) {
        return Promise.resolve();
      } else {
        return Promise.reject(_.message);
      }
    };
  };

  const cancelRejected = () => {
    setRejectReason(selectOptions?.rejectReason[0].value);
    setRejectReasonOption(selectOptions?.rejectReason[0].value);
    setRejectPopup(false);
  };

  return (
    <>
      <h1 className="title">Airdrop Single</h1>
      {loading ? (
        <Loader />
      ) : (
        <div className={c.stakingForm}>
          <Form
            form={form}
            name="stakingEdit"
            onFinish={editData}
            autoComplete="off"
            initialValues={singleData}
            scrollToFirstError>
            <div className={c.single}>
              <div className={c.stakingInfo}>
                <div className={c.stakingInfo__buttons}>
                  <Button
                    type="primary"
                    icon={<LeftOutlined />}
                    onClick={() => navigate(-1)}>
                    Back
                  </Button>
                  <Button
                    type="primary"
                    icon={<ReloadOutlined />}
                    onClick={() => form.resetFields()}>
                    Reset
                  </Button>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      icon={<SaveOutlined />}>
                      Save
                    </Button>
                  </Form.Item>
                </div>
                <div className={c.stakingInfo__list}>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      label="User">
                      <AutoComplete
                        options={userList}
                        notFoundContent={'Nothing found'}
                        onSelect={onSelect}
                        onSearch={onSearch}
                        placeholder="Search User"
                        defaultValue={
                          singleData?.user
                            ? singleData.user.username +
                              '(' +
                              singleData.user.email +
                              ')'
                            : ''
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="contactEmail"
                      label="Contact Email"
                      rules={[
                        {
                          type: 'email',
                        },
                        {
                          required: true,
                          message: 'Required',
                        },
                      ]}>
                      <Input onDoubleClick={CopyToClipboard} />
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="code"
                      label="Symbol"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                          whitespace: true,
                        },
                      ]}>
                      <Select
                        placeholder="Select Code"
                        style={{ width: '100%' }}>
                        {selectOptions?.availableCurrencies?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={c.stakingInfo__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="telegram"
                      label="Telegram">
                      <Input onDoubleClick={CopyToClipboard} />
                    </Form.Item>
                  </div>
                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '48.6%' }}>
                    <div className={c.aside__itemTitle}>
                      <span
                        style={{
                          display: 'inline-block',
                          fontFamily: 'SimSun, sans-serif',
                          color: '#ff4d4f',
                          fontSize: '14px',
                          marginRight: '5px',
                        }}>
                        *
                      </span>
                      Relations with the C/T:
                    </div>
                    <Form.Item style={{ width: '100%' }}>
                      <Input.Group style={{ display: 'flex', width: '100%' }}>
                        <Form.Item
                          style={{
                            width: '100%',
                            marginBottom: 0,
                          }}
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                          ]}
                          name={['relationWithCoin', 'relation']}>
                          <Select
                            placeholder="Select province"
                            style={{ width: '100%' }}
                            onChange={e => {
                              setRelationWithCoinOption(e);
                              form.setFieldsValue({
                                relationWithCoin: { value: null },
                              });
                            }}>
                            {selectOptions?.relationWithCoin?.map(item => (
                              <Option key={item.value} value={item.value}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {relationWithCoinOption === 'other' && (
                          <Form.Item
                            style={{
                              width: '100%',
                              marginBottom: 0,
                            }}
                            name={['relationWithCoin', 'value']}
                            rules={[
                              {
                                required: relationWithCoinOption === 'other',
                                message: 'Required',
                              },
                            ]}>
                            <Input
                              style={{ width: '100%' }}
                              placeholder="Input Relation"
                            />
                          </Form.Item>
                        )}
                      </Input.Group>
                    </Form.Item>
                  </div>

                  <div
                    className={c.stakingInfo__item}
                    style={{ width: '100%' }}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name={['extra', 'airdrop', 'info']}
                      label="Any other information that you would like to provide?"
                      rules={[
                        {
                          required: false,
                        },
                      ]}>
                      <TextArea
                        placeholder="Controlled autosize"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <div
                    className={c.singleGroup__title}
                    onClick={() => toggleAirdropTypes('airdrop_from_funds')}>
                    Fund
                    <div
                      className={classNames(
                        c.periodCheckbox,
                        airdropTypes.some(e => e === 'airdrop_from_funds') &&
                          c.active,
                      )}>
                      {airdropTypes.some(e => e === 'airdrop_from_funds') && (
                        <img src={checkMark} alt="" />
                      )}
                    </div>
                  </div>
                  {airdropTypes.some(e => e === 'airdrop_from_funds') && (
                    <div className={c.stakingInfo__list}>
                      <div className={c.stakingInfo__item}>
                        <Form.Item
                          style={{
                            flexDirection: 'column',
                          }}
                          name={['extra', 'airdrop', 'fund', 'totalAmount']}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                            {
                              message: validateMsg('min', 10 ** -6),
                              validator: validateMinAmount(10 ** -6),
                            },
                            {
                              message: validateMsg('max', 1000000000),
                              validator: validateMaxAmount(1000000000),
                            },
                          ]}
                          label="Total Amount">
                          <NumericInput
                            precision={6}
                            onDoubleClick={CopyToClipboard}
                            onBlur={currentValue =>
                              onBlur(currentValue, 'totalAmount')
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className={c.stakingInfo__item}>
                        <Form.Item
                          style={{
                            flexDirection: 'column',
                          }}
                          label="Amount Per User"
                          name={['extra', 'airdrop', 'fund', 'amountPerUser']}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                            {
                              message: validateMsg('min', 10 ** -6),
                              validator: validateMinAmount(10 ** -6),
                            },
                            {
                              message: validateMsg('max', 1000000000),
                              validator: validateMaxAmount(1000000000),
                            },
                          ]}>
                          <NumericInput
                            precision={6}
                            onDoubleClick={CopyToClipboard}
                            onBlur={currentValue =>
                              onBlur(currentValue, 'amountPerUser')
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className={c.stakingInfo__item} />
                      <div className={c.stakingInfo__item} />
                    </div>
                  )}
                </div>
                <div>
                  <div
                    className={c.singleGroup__title}
                    onClick={() =>
                      toggleAirdropTypes('airdrop_from_airdrop_wheel')
                    }>
                    Wheel
                    <div
                      className={classNames(
                        c.periodCheckbox,
                        airdropTypes.some(
                          e => e === 'airdrop_from_airdrop_wheel',
                        ) && c.active,
                      )}>
                      {airdropTypes.some(
                        e => e === 'airdrop_from_airdrop_wheel',
                      ) && <img src={checkMark} alt="" />}
                    </div>
                  </div>
                  {airdropTypes.some(
                    e => e === 'airdrop_from_airdrop_wheel',
                  ) && (
                    <div className={c.stakingInfo__list}>
                      <div className={c.stakingInfo__item}>
                        <Form.Item
                          style={{
                            flexDirection: 'column',
                          }}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                            {
                              message: validateMsg('min', 10 ** -6),
                              validator: validateMinAmount(10 ** -6),
                            },
                            {
                              message: validateMsg('max', 1000000000),
                              validator: validateMaxAmount(1000000000),
                            },
                          ]}
                          name={['extra', 'airdrop', 'wheel', 'totalAmount']}
                          label="Total Amount">
                          <NumericInput
                            precision={6}
                            onDoubleClick={CopyToClipboard}
                            onBlur={currentValue =>
                              onBlur(currentValue, 'totalAmount')
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className={c.stakingInfo__item}>
                        <Form.Item
                          style={{
                            flexDirection: 'column',
                          }}
                          name={['extra', 'airdrop', 'wheel', 'minReward']}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                            {
                              message: validateMsg('min', 10 ** -6),
                              validator: validateMinAmount(10 ** -6),
                            },
                            {
                              message: validateMsg('max', 1000000000),
                              validator: validateMaxAmount(1000000000),
                            },
                          ]}
                          label="Min">
                          <NumericInput
                            precision={6}
                            onDoubleClick={CopyToClipboard}
                            onBlur={currentValue =>
                              onBlur(currentValue, 'minReward')
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className={c.stakingInfo__item}>
                        <Form.Item
                          style={{
                            flexDirection: 'column',
                          }}
                          name={['extra', 'airdrop', 'wheel', 'maxReward']}
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              message: 'Required',
                            },
                            {
                              message: validateMsg('min', 10 ** -6),
                              validator: validateMinAmount(10 ** -6),
                            },
                            {
                              message: validateMsg('max', 1000000000),
                              validator: validateMaxAmount(1000000000),
                            },
                          ]}
                          label="Max">
                          <NumericInput
                            precision={6}
                            onDoubleClick={CopyToClipboard}
                            onBlur={currentValue =>
                              onBlur(currentValue, 'maxReward')
                            }
                          />
                        </Form.Item>
                      </div>
                      <div className={c.stakingInfo__item} />
                    </div>
                  )}
                </div>
              </div>
              <div className={c.aside}>
                <div className={c.aside__inner}>
                  <div className={c.aside__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name="paymentAmount"
                      label="Payment Amount"
                      validateTrigger="onBlur"
                      rules={[
                        {
                          required: true,
                          message: 'Required',
                        },
                        {
                          message: validateMsg('min', 10 ** -6),
                          validator: validateMinAmount(10 ** -6),
                        },
                        {
                          message: validateMsg('max', 1000000000),
                          validator: validateMaxAmount(1000000000),
                        },
                      ]}>
                      <NumericInput
                        precision={6}
                        disabled={singleData?.order?.status === 'paid'}
                        onDoubleClick={CopyToClipboard}
                        onBlur={currentValue =>
                          onBlur(currentValue, 'paymentAmount')
                        }
                      />
                    </Form.Item>
                  </div>
                  {airdropProgramId && (
                    <div className={c.aside__item}>
                      <Form.Item
                        name={'created'}
                        style={{
                          flexDirection: 'column',
                        }}
                        label="Created">
                        <DatePicker style={{ width: '100%' }} disabled />
                      </Form.Item>
                    </div>
                  )}
                  <div className={c.aside__item}>
                    <Form.Item
                      style={{
                        flexDirection: 'column',
                      }}
                      name={['extra', 'airdrop', 'processingStatus']}
                      label="Processing Status">
                      <Select
                        placeholder="select your Processing Status"
                        onChange={e => {
                          setListingStatus(e);
                          setRejectReasonOption('');
                          setRejectReason('');
                        }}>
                        {selectOptions?.airdropProcessingStatus?.map(item => (
                          <Option
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  {rejectReason && (
                    <div className={c.aside__item}>
                      <div className={c.aside__itemTitle}>Reject Reason</div>
                      <div style={{ fontSize: '12px', marginBottom: '1.5rem' }}>
                        {rejectReason}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Form>
          {rejectPopup && (
            <Modal
              title={'Reject Reason'}
              onOk={() => setRejectPopup(false)}
              onClose={() => cancelRejected()}>
              <div className={c.stakingInfo__item} style={{ width: '100%' }}>
                <Select
                  style={{ width: '300px' }}
                  defaultValue={selectOptions?.rejectReason[0].value}
                  onChange={e => {
                    setRejectReason(e);
                    setRejectReasonOption(e);
                  }}>
                  {selectOptions?.rejectReason?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              {rejectReasonOption === 'other' && (
                <div
                  className={c.stakingInfo__item}
                  style={{ width: '100%', marginTop: '1rem' }}>
                  <TextArea
                    initialValue={singleData.name}
                    placeholder="Controlled autosize"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    onChange={e => setRejectReason(e.target.value)}
                  />
                </div>
              )}
            </Modal>
          )}
          {airdropProgramId && (
            <Comments
              userComments={userComments}
              systemComments={systemComments}
              getSingleData={getAirdropById}
              setUserComments={setUserComments}
              setSystemComments={setSystemComments}
              id={airdropProgramId}
              requests
            />
          )}
        </div>
      )}
    </>
  );
};

export default AirdropForm;
