import React, { createRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';

import { Button, Checkbox, Form, Input } from 'antd';

import c from './Login.module.scss';
import { loginAsync } from '../../store/slices/userSlice/userSlice';

const Login = () => {
  const dispatch = useDispatch();
  const recaptchaRef = createRef();
  const [emptyRecaptcha, setEmptyRecaptcha] = useState(false);
  const [emptyLoginPassword, setEmptyLoginPassword] = useState(false);
  console.log(recaptchaRef.current);
  const onFinish = async values => {
    if (values.username && values.password) {
      setEmptyLoginPassword(false);
      const recaptchaValue = recaptchaRef.current?.getValue();
      console.log(recaptchaValue);
      if (!recaptchaValue) {
        setEmptyRecaptcha(true);
        return;
      }
      setEmptyRecaptcha(false);
      dispatch(
        loginAsync({
          username: values.username,
          password: values.password,
          recaptchaValue,
        }),
      );
    } else {
      setEmptyLoginPassword(true);
    }
  };
  const onFinishFailed = () => {
    setEmptyLoginPassword(true);
  };
  return (
    <div className={c.login}>
      <Form
        className={c.form}
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}>
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          wrapperCol={{
            offset: 8,
            span: 16,
          }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
          {emptyLoginPassword && (
            <div style={{ color: 'red', marginTop: '15px' }}>
              Login and Password fields are required
            </div>
          )}
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_API_RECAPTCHA}
            ref={recaptchaRef}
            onChange={() => setEmptyRecaptcha(false)}
          />
          {emptyRecaptcha && (
            <div style={{ color: 'red' }}> Please complete recaptcha!</div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
