import React from 'react';
import StakingForm from '../../../components/Forms/StakingForm/StakingForm';

const StakingCreate = () => {
  return (
    <div>
      <StakingForm />
    </div>
  );
};

export default StakingCreate;
