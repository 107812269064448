import axios from 'axios';
import Cookies from 'js-cookie';

const apiAxiosLanding = axios.create({
  baseURL: process.env.REACT_APP_API_LANDING_URL,
});

const apiAxiosLandingServer = axios.create({
  baseURL: process.env.REACT_APP_API_LANDING_SERVER_URL,
});

const apiAxiosExchange = axios.create({
  baseURL: process.env.REACT_APP_API_EXCHANGE_URL,
});

const responseFunc = async res => {
  const { status, message, result } = res.data;
  if (status === true) {
    return result;
  }
  throw new Error(message);
};

const requestFunc = req => {
  if (req) {
    req.headers.Authorization = Cookies.get('manageToken');
    return req;
  }
};

[apiAxiosExchange, apiAxiosLanding, apiAxiosLandingServer].forEach(i => {
  i.interceptors.response.use(responseFunc);
  i.interceptors.request.use(requestFunc);
});

export { apiAxiosLanding, apiAxiosExchange, apiAxiosLandingServer };
