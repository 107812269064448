import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { apiGetMe, apiLogin, apiLogout } from './userAPI';

const initialState = {
  userData: {},
  loading: false,
};

export const getMeAsync = createAsyncThunk(
  'user/getMe',
  async (_, { rejectWithValue }) => {
    try {
      return apiGetMe();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const loginAsync = createAsyncThunk(
  'user/login',
  async ({ username, password, recaptchaValue }, { rejectWithValue }) => {
    try {
      return apiLogin(username, password, recaptchaValue);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logoutAsync = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      return apiLogout();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.userData = action.payload;
    },
    removeUser(state) {
      state.userData = {};
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getMeAsync.pending]: state => {
      state.loading = true;
    },
    [getMeAsync.fulfilled]: (state, action) => {
      state.userData = action.payload;
      state.loading = false;
    },
    [getMeAsync.rejected]: state => {
      state.loading = false;
    },
    [logoutAsync.pending]: state => {
      state.loading = true;
    },
    [logoutAsync.fulfilled]: state => {
      Cookies.remove('manageToken');
      state.userData = {};
      state.loading = false;
    },
    [logoutAsync.rejected]: state => {
      state.loading = false;
      console.log('rejected');
    },
    [loginAsync.pending]: state => {
      state.loading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      Cookies.set('manageToken', action.payload.token, { expires: 100 });
      state.userData = action.payload;
      state.loading = false;
    },
    [loginAsync.rejected]: state => {
      state.loading = false;
      toast.error('Login or password is invalid');
    },
  },
});

export const { setUser, removeUser, setLoading } = userSlice.actions;

export default userSlice.reducer;
