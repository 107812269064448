import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import c from '../../Listing/ListingList/ListingList.module.scss';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Select,
  Switch,
} from 'antd';
import {
  ClearOutlined,
  DownloadOutlined,
  EditOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import Loader from '../../../components/Loader/Loader';
import {
  apiRequestList,
  apiRequestListCsv,
  apiRequestSelectOptions,
} from '../../../store/slices/requestSlice/requestAPI';
import moment from 'moment';
import { Filter } from '../../../helper/filter';
import { Option } from 'antd/es/mentions';
import classNames from 'classnames';
import ArrowIcon from '../../../assets/images/arrow.svg';

const AirdropList = () => {
  const [loading, setLoading] = useState(false);
  const [stakingTable, setStakingTable] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterValues, setFilterValues] = useState([]);
  const [filterArray, setFilterArray] = useState([]);
  const [sortData, setSortData] = useState({ created: -1 });

  const [selectOptions, setSelectOptions] = useState({});

  const { RangePicker } = DatePicker;

  const [form] = Form.useForm();

  useEffect(async () => {
    await apiRequestSelectOptions()
      .then(setSelectOptions)
      .catch(e => console.log(e));
  }, []);

  const filterData = async () => {
    let filter = new Filter()
      .contains(
        filterValues.searchOption,
        filterValues.searchValue,
        filterValues.searchInverse,
      )
      .betweenDates(
        'created',
        filterValues.created && moment(filterValues.created[0]).unix() * 1000,
        filterValues.created && moment(filterValues.created[1]).unix() * 1000,
      )
      .betweenDates(
        'updated',
        filterValues.updated && moment(filterValues.updated[0]).unix() * 1000,
        filterValues.updated && moment(filterValues.updated[1]).unix() * 1000,
      )
      .exactly('extra.airdrop.processingStatus', filterValues.processingStatus)
      .arrayContains('extra.airdrop.type', [filterValues.airdropType])
      .query();
    setFilterArray(filter);
    await stakingList(filter);
  };

  const downloadCsv = async () => {
    await apiRequestListCsv(filterArray, sortData, activePage, 10000, 'airdrop')
      .then(e => {
        const a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + e;
        a.download = 'airdrop.csv';
        a.click();
      })
      .catch(e => console.log(e));
  };

  const changeSort = item => {
    setSortData(
      sortData[item] ? { [item]: -1 * [sortData[item]] } : { [item]: 1 },
    );
  };

  const stakingList = async filter => {
    setLoading(true);
    await apiRequestList(filter, sortData, activePage, itemsPerPage, 'airdrop')
      .then(setStakingTable)
      .catch(e => console.log(e));
    setLoading(false);
  };

  const filteredTh = (value, title) => {
    return (
      <th className={c.listing__table_th} onClick={() => changeSort(value)}>
        <span>{title}</span>
        {sortData[value] && (
          <span
            className={classNames(
              c.tableSort__arrow,
              sortData[value] === -1 && c.rotate,
            )}>
            <img src={ArrowIcon} alt="" />
          </span>
        )}
      </th>
    );
  };

  useEffect(async () => {
    await filterData();
  }, [activePage, itemsPerPage, sortData, filterValues]);

  return (
    <div className={c.listing}>
      <div className={c.listing__heading}>
        <h1 className="title">Airdrop</h1>
        <Link to={`/airdrop/create`}>
          <Button type="primary" size={'large'}>
            Create
          </Button>
        </Link>
      </div>
      <div className={c.listing__filter}>
        <Form
          form={form}
          name="filter"
          onFinish={values => {
            console.log(values);
            setFilterValues(values);
            setActivePage(1);
          }}
          autoComplete="off">
          <div className={c.listing__filterList}>
            <div className={c.listing__filterItem} style={{ width: 180 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchValue"
                label="Search">
                <Input placeholder="Search..." />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchOption"
                initialValue="code"
                label="Search Option">
                <Select placeholder="Select Search Option">
                  <Option value="code">Symbol</Option>
                  <Option value="name">Name</Option>
                  <Option value="contactEmail">Contact Email</Option>
                  <Option value="telegram">Telegram</Option>
                  <Option value="user.username">Username</Option>
                  <Option value="user.email">User Email</Option>
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 50 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchInverse"
                label="Inverse">
                <Switch />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="processingStatus"
                label="Processing Status">
                <Select
                  style={{ width: '100%' }}
                  defaultValue={{ value: 'All' }}>
                  <Option key={'All'} value={null}>
                    All
                  </Option>
                  {selectOptions?.airdropProcessingStatus?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="airdropType"
                label="Airdrop Type">
                <Select
                  style={{ width: '100%' }}
                  defaultValue={{ value: 'All' }}>
                  <Option key={'All'} value={null}>
                    All
                  </Option>
                  {selectOptions?.airdropType?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 210 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="created"
                label="Created">
                <RangePicker />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 210 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="updated"
                label="Updated">
                <RangePicker />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<FilterOutlined />}>
                  Filter
                </Button>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem}>
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => {
                  form.resetFields();
                  setFilterValues([]);
                }}>
                Reset
              </Button>
            </div>
            <div className={c.listing__filterItem}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => downloadCsv()}>
                Download
              </Button>
            </div>
          </div>
        </Form>
      </div>
      {loading ? (
        <Loader fixed={false} />
      ) : stakingTable?.list ? (
        <div className={c.listing__table}>
          <table>
            <thead>
              <tr>
                <th className="sticky-left">Symbol</th>
                <th>Status</th>
                <th>Contact Email</th>
                <th>Telegram</th>
                <th>Username</th>
                {filteredTh('created', 'Created')}
                {filteredTh('updated', 'Updated')}
                <th>Wheel</th>
                <th>Fund</th>
                <th className={'sticky-right'} />
              </tr>
            </thead>
            <tbody>
              {stakingTable.list.length ? (
                stakingTable.list.map((e, index) => (
                  <tr key={'' + e.code + index}>
                    <td className={'sticky-left'}>
                      <span>{e.code}</span>
                    </td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {e.extra.airdrop.processingStatus}
                    </td>
                    <td>{e.contactEmail}</td>
                    <td>{e.telegram}</td>
                    <td>{e.user.username}</td>
                    <td>
                      {e.created ? moment(e.created).format('YYYY-MM-DD') : '-'}
                    </td>
                    <td>
                      {e.updated ? moment(e.updated).format('YYYY-MM-DD') : '-'}
                    </td>
                    <td>{e.extra.airdrop.wheel.totalAmount || '-'}</td>
                    <td>{e.extra.airdrop.fund.totalAmount || '-'}</td>
                    <td className={'sticky-right'}>
                      <Link to={`/airdrop/${e.id}`}>
                        <Button
                          type="primary"
                          icon={<EditOutlined />}
                          size={'large'}>
                          Edit
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={'100%'}
                    style={{ padding: '10px', textAlign: 'center' }}>
                    NO Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className={'sticky-left'} style={{ marginTop: '10px' }}>
            <Pagination
              onChange={(page, pageSize) => {
                setActivePage(page);
                setItemsPerPage(pageSize);
              }}
              defaultCurrent={activePage}
              defaultPageSize={itemsPerPage}
              total={stakingTable.count}
              pageSizeOptions={[10, 15, 20]}
            />
          </div>
        </div>
      ) : (
        <div style={{ flexGrow: 1, textAlign: 'center' }}>no Data</div>
      )}
    </div>
  );
};

export default AirdropList;
