import { apiAxiosLanding, apiAxiosLandingServer } from '../../../api';

export async function apiListingList(filter, sort, page, itemsPerPage) {
  return await apiAxiosLanding.post(
    `/admin-cms/listing-list?page=${page}&itemsPerPage=${itemsPerPage}`,
    {
      filter,
      sort,
    },
  );
}

export async function apiListingListCsv(filter, sort, page, itemsPerPage) {
  return await apiAxiosLanding.post(
    `/admin-cms/csv/listings?page=${page}&itemsPerPage=${itemsPerPage}`,
    {
      filter,
      sort,
    },
  );
}

export async function apiListingSingle(id) {
  return await apiAxiosLanding.get(`/admin-cms/listing/${id}`);
}
export async function apiListingSingleComments(id, comment) {
  return await apiAxiosLanding.post(`/admin-cms/listing/${id}/comment`, {
    comment,
  });
}

export async function apiListingSingleEdit(id, data) {
  return await apiAxiosLanding.put(`/admin-cms/listing/${id}`, data);
}

export async function apiListingSingleCreate(data) {
  return await apiAxiosLanding.post(`/admin-cms/listing/`, data);
}

export async function apiImageUpload(formData) {
  return await apiAxiosLandingServer.post(`uploader/upload/`, formData);
}
