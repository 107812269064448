import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  apiListingList,
  apiListingListCsv,
} from '../../../store/slices/listingSlice/listingAPI';
import Loader from '../../../components/Loader/Loader';
import c from './ListingList.module.scss';
import moment from 'moment';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Pagination,
  Radio,
  Select,
  Switch,
  Tag,
} from 'antd';
import {
  ClearOutlined,
  DownloadOutlined,
  EditOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { Filter } from '../../../helper/filter';
import ArrowIcon from '../../../assets/images/arrow.svg';
import classNames from 'classnames';
import { apiRequestSelectOptions } from '../../../store/slices/requestSlice/requestAPI';
import imagePlaceholder from '../../../assets/images/imagePlaceholder.png';
import { LazyImage } from 'react-lazy-images';

const { Option } = Select;
const { RangePicker } = DatePicker;
const ListingRequestType = [
  { name: 'Listing', value: 'listing' },
  { name: 'Voting', value: 'voting' },
  { name: 'Both', value: 'both' },
];

const ListingList = () => {
  const [listingTable, setListingTable] = useState({});
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [sortData, setSortData] = useState({ created: -1 });
  const [selectOptions, setSelectOptions] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [filterArray, setFilterArray] = useState([]);

  const [form] = Form.useForm();

  const filteredTh = (value, title) => {
    return (
      <th className={c.listing__table_th} onClick={() => changeSort(value)}>
        <span>{title}</span>
        {sortData[value] && (
          <span
            className={classNames(
              c.tableSort__arrow,
              sortData[value] === -1 && c.rotate,
            )}>
            <img src={ArrowIcon} alt="" />
          </span>
        )}
      </th>
    );
  };

  const changeSort = item => {
    setSortData(
      sortData[item] ? { [item]: -1 * [sortData[item]] } : { [item]: 1 },
    );
  };

  useEffect(async () => {
    await apiRequestSelectOptions()
      .then(setSelectOptions)
      .catch(e => console.log(e));
  }, []);

  useEffect(async () => {
    await filterData();
  }, [activePage, itemsPerPage, sortData, filterValues]);

  const getListingList = async filter => {
    setLoading(true);
    await apiListingList(filter, sortData, activePage, itemsPerPage)
      .then(setListingTable)
      .catch(e => console.log(e));
    setLoading(false);
  };

  const filterData = async () => {
    let filter = new Filter()
      .contains(
        filterValues.searchOption,
        filterValues.searchValue,
        filterValues.searchInverse,
      )
      .in('blockchain', filterValues.blockchain)
      .exactly('listingType', filterValues.listingType)
      .exactly('listingPackage', filterValues.listingPackage)
      .exactly('status', filterValues.status)
      .exactly(
        'listingRequestType.listing',
        filterValues?.listingRequestType === 'listing' ||
          filterValues?.listingRequestType === 'both'
          ? true
          : undefined,
      )
      .exactly(
        'listingRequestType.voting',
        filterValues?.listingRequestType === 'voting' ||
          filterValues?.listingRequestType === 'both'
          ? true
          : undefined,
      )
      .exactly('addToVoting', filterValues.addToVoting)
      .empty('paymentTransactionId', filterValues.paymentTransactionId)
      .betweenDates(
        'listingDate',
        filterValues.listedDate &&
          moment(filterValues.listedDate[0]).unix() * 1000,
        filterValues.listedDate &&
          moment(filterValues.listedDate[1]).unix() * 1000,
      )
      .betweenDates(
        'created',
        filterValues.created && moment(filterValues.created[0]).unix() * 1000,
        filterValues.created && moment(filterValues.created[1]).unix() * 1000,
      )
      .betweenDates(
        'updated',
        filterValues.updated && moment(filterValues.updated[0]).unix() * 1000,
        filterValues.updated && moment(filterValues.updated[1]).unix() * 1000,
      )
      .query();
    setFilterArray(filter);
    await getListingList(filter);
  };

  const downloadCsv = async () => {
    await apiListingListCsv(filterArray, sortData, activePage, 10000)
      .then(e => {
        const blob = new Blob([e]);
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, e);
        } else {
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'listing.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      })
      .catch(e => console.log(e));
  };

  function tagRender(props) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = event => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color="blue"
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  }

  return (
    <div className={c.listing}>
      <div className={c.listing__heading}>
        <h1 className="title">ListingList</h1>
        <Link to={`/listing/create`}>
          <Button type="primary" size={'large'}>
            Create
          </Button>
        </Link>
      </div>
      <div className={c.listing__filter}>
        <Form
          form={form}
          name="filter"
          onFinish={values => {
            setFilterValues(values);
            setActivePage(1);
          }}
          autoComplete="off">
          <div className={c.listing__filterList}>
            <div className={c.listing__filterItem} style={{ width: 180 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchValue"
                label="Search">
                <Input placeholder="Search..." />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchOption"
                initialValue="code"
                label="Search Option">
                <Select placeholder="Select Search Option">
                  <Option value="code">Symbol</Option>
                  <Option value="name">Name</Option>
                  <Option value="contactEmail">Contact Email</Option>
                  <Option value="telegram">Telegram</Option>
                  <Option value="user.username">Username</Option>
                  <Option value="user.email">User Email</Option>
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 55 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="searchInverse"
                label="Inverse">
                <Switch />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="status"
                label="Status">
                <Select
                  style={{ width: '100%' }}
                  defaultValue={{ value: 'All' }}>
                  <Option key={'All'} value={null}>
                    All
                  </Option>
                  {selectOptions?.status?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="listingRequestType"
                label="Reason">
                <Select style={{ width: '100%' }} placeholder={'Set reason'}>
                  {ListingRequestType.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 150 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="addToVoting"
                label="Added To Voting">
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 170 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="blockchain"
                label="BlockChain">
                <Select
                  mode="multiple"
                  showArrow
                  tagRender={tagRender}
                  style={{ width: '100%' }}
                  placeholder={'Set blockchain'}>
                  {selectOptions?.blockchain?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 130 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="listingType"
                label="Listing Type">
                <Select
                  style={{ width: '100%' }}
                  defaultValue={{ value: 'All' }}>
                  <Option key={'All'} value={null}>
                    All
                  </Option>
                  {selectOptions?.swapType?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 150 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="listingPackage"
                label="Listing Package">
                <Select
                  style={{ width: '100%' }}
                  defaultValue={{ value: 'All' }}>
                  <Option key={'All'} value={null}>
                    All
                  </Option>
                  {selectOptions?.listingPackage?.map(item => (
                    <Option key={item.value} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 150 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="paymentTransactionId"
                label="Payment Transaction Id">
                <Radio.Group>
                  <Radio value={1}>True</Radio>
                  <Radio value={-1}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 210 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="listedDate"
                label="Listed Date">
                <RangePicker />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 210 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="created"
                label="Created">
                <RangePicker />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem} style={{ width: 210 }}>
              <Form.Item
                style={{
                  flexDirection: 'column',
                  marginBottom: 0,
                }}
                name="updated"
                label="Updated">
                <RangePicker />
              </Form.Item>
            </div>
            <div className={c.listing__filterItem}>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<FilterOutlined />}>
                  Filter
                </Button>
              </Form.Item>
            </div>
            <div className={c.listing__filterItem}>
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => {
                  form.resetFields();
                  setFilterValues([]);
                }}>
                Reset
              </Button>
            </div>
            <div className={c.listing__filterItem}>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => downloadCsv()}>
                Download
              </Button>
            </div>
          </div>
        </Form>
      </div>
      {loading ? (
        <Loader fixed={false} />
      ) : listingTable?.list ? (
        <div className={c.listing__table}>
          <table>
            <thead>
              <tr>
                <th className="sticky-left">Symbol</th>
                <th>Name</th>
                <th>Status</th>
                <th>Contact Email</th>
                <th>Telegram</th>
                <th>Username</th>
                <th>Blockchain</th>
                {filteredTh('created', 'Created')}
                {filteredTh('updated', 'Updated')}
                {filteredTh('paymentAmount', 'Payment')}
                {filteredTh('listedDate', 'Listing date')}
                {filteredTh(
                  'depositWithdrawalActivationDate',
                  'D/W Activation Date',
                )}
                {filteredTh('tradeActivationDate', 'Trade activation date')}
                <th>ListingList type</th>
                <th>ListingList package</th>
                <th>Pairs</th>
                {filteredTh('currentRoundVotes', 'Current Round Votes')}
                {filteredTh('totalVotes', 'Total Votes')}
                {filteredTh('badges', 'Badges')}
                <th className={'sticky-right'} />
              </tr>
            </thead>
            <tbody>
              {listingTable.list.length ? (
                listingTable.list.map((e, index) => (
                  <tr key={'' + e.code + index}>
                    <td className={'sticky-left'} style={{ display: 'flex' }}>
                      <div className={c.image}>
                        <LazyImage
                          src={e.logoName || imagePlaceholder}
                          alt="Buildings with tiled exteriors, lit by the sunset."
                          actual={({ imageProps }) => <img {...imageProps} />}
                          placeholder={({ ref }) => <div ref={ref} />}
                          loading={() => (
                            <img src={imagePlaceholder} alt="placeholder" />
                          )}
                          error={() => (
                            <img src={imagePlaceholder} alt="placeholder" />
                          )}
                        />
                      </div>
                      <span>{e.code}</span>
                    </td>
                    <td>{e.name}</td>
                    <td style={{ textTransform: 'capitalize' }}>{e.status}</td>
                    <td style={{ textTransform: 'initial' }}>
                      {e.contactEmail}
                    </td>
                    <td>{e.telegram}</td>
                    <td>{e.user.username}</td>
                    <td>{e.blockchain}</td>
                    <td>
                      {e.created ? moment(e.created).format('YYYY-MM-DD') : '-'}
                    </td>
                    <td>
                      {e.updated ? moment(e.updated).format('YYYY-MM-DD') : '-'}
                    </td>
                    <td className={e.paymentTransactionId ? 'green' : 'red'}>
                      {e.paymentAmount} {e.paymentCurrency}
                    </td>
                    <td>
                      {e.listedDate
                        ? moment(e.listedDate).format('YYYY-MM-DD')
                        : '-'}
                    </td>
                    <td>
                      {e.depositWithdrawalActivationDate
                        ? moment(e.depositWithdrawalActivationDate).format(
                            'YYYY-MM-DD',
                          )
                        : '-'}
                    </td>
                    <td>
                      {e.tradeActivationDate
                        ? moment(e.tradeActivationDate).format('YYYY-MM-DD')
                        : '-'}
                    </td>
                    <td>{e.listingType}</td>
                    <td>{e.listingPackage}</td>

                    <td>
                      {e.pairs?.map(item => (
                        <span key={item.name}>{item.name} </span>
                      ))}
                    </td>
                    <td>{e.currentRoundVotes}</td>
                    <td>{e.totalVotes}</td>
                    <td>{e.badges}</td>
                    <td className={'sticky-right'}>
                      <Link to={`/listing/${e.id}`}>
                        <Button
                          type="primary"
                          icon={<EditOutlined />}
                          size={'large'}>
                          Edit
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={'100%'}
                    style={{ padding: '10px', textAlign: 'center' }}>
                    NO Data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className={'sticky-left'} style={{ marginTop: '10px' }}>
            <Pagination
              onChange={(page, pageSize) => {
                setActivePage(page);
                setItemsPerPage(pageSize);
              }}
              defaultCurrent={activePage}
              defaultPageSize={itemsPerPage}
              total={listingTable.count}
              pageSizeOptions={[10, 15, 20]}
            />
          </div>
        </div>
      ) : (
        <div style={{ flexGrow: 1, textAlign: 'center' }}>no Data</div>
      )}
    </div>
  );
};

export default ListingList;
