import { apiAxiosExchange, apiAxiosLanding } from '../../../api';

export async function apiRequestList(filter, sort, page, itemsPerPage, type) {
  return await apiAxiosLanding.post(
    `/request/list?page=${page}&itemsPerPage=${itemsPerPage}`,
    {
      filter: [
        ...filter,
        { field: 'type', value: type, inverse: false, type: 1 },
      ],
      sort,
    },
  );
}

export async function apiRequestSelectOptions() {
  return await apiAxiosLanding.get(`/request/info`);
}

export async function apiGetRequestById(id) {
  return await apiAxiosLanding.get(`/request/${id}`);
}

export async function apiEditRequestById(id, data) {
  return await apiAxiosLanding.put(`/request/${id}`, data);
}
export async function apiEditStakingStatusById(id, index, status) {
  return await apiAxiosLanding.put(
    `/request/staking-item-status/${id}?index=${index}&status=${status}`,
  );
}

export async function apiRequestCreate(data) {
  return await apiAxiosLanding.post(`/request`, data);
}

export async function apiCreateStakingComment(id, comment) {
  return await apiAxiosLanding.post(`/request/${id}/comment`, {
    comment,
  });
}
export async function apiSearchUser(text) {
  return await apiAxiosExchange.post(`/user/search`, {
    search: text,
    page: 1,
    itemsPerPage: 1000,
    sortBy: '-updated',
  });
}

export async function apiRequestListCsv(
  filter,
  sort,
  page,
  itemsPerPage,
  type,
) {
  return await apiAxiosLanding.post(
    `/requests/csv?page=${page}&itemsPerPage=${itemsPerPage}`,
    {
      filter: [
        ...filter,
        { field: 'type', value: type, inverse: false, type: 1 },
      ],
      sort,
    },
  );
}
