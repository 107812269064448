import React from 'react';
import c from './ScrollToTop.module.scss';
import { Button, Tooltip } from 'antd';
import { UpOutlined } from '@ant-design/icons';

const ScrollToTop = ({ onClick }) => {
  return (
    <div className={c.staking__scrollTop}>
      <Tooltip title="Scroll To Top ">
        <Button
          type="primary"
          style={{ width: '40px', height: '40px' }}
          shape="circle"
          icon={<UpOutlined />}
          onClick={onClick}
        />
      </Tooltip>
    </div>
  );
};

export default ScrollToTop;
